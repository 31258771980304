<template>
	<div>
		<h2>{{ $t('monte.reservation.reservation_semence') }}</h2>
		<div class="box">
			<ReservationMonte
				:supplier_id="parseInt(tiers_id)"
				:available_tiers="all_tiers"
				:available_mares="juments"
				:show_title="false"
			/>
		</div>
	</div>
</template>

<script type="text/javascript">
	import Contract from '@/mixins/Contract.js'
	import TiersMixin from '@/mixins/Tiers.js'

	export default {
		name: "MonteReservation",
		mixins: [Contract, TiersMixin],
		props: ['tiers_id'],
		data() {
			return {
				all_tiers: [],
				juments: []
			}
		},
		mounted() {
			this.init_component()
		},
		methods: {
			async init_component() {
				this.all_tiers  = await this.loadTiers()
				this.juments    = await this.loadJuments()
			}
		},
		components: {
			ReservationMonte : () => import('@/components/Contract/Reservation/ReservationList')
		}
	}
</script>
